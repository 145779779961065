import logo from '../Images/Logo-Tip-Top.webp';
import {Link} from 'react-router-dom';
import tel from '../Images/Telefon-Symbol-klein.webp'
export default function Nav(){
    return(
        <div>
            <nav className="navbar navbar-expand-lg sticky-top bg-white shadow-sm">
                <div class="container">
                    <Link to="/" class="navbar-brand d-none d-sm-none d-md-none d-lg-block">
                        <img src={logo} height="80" Width="86" alt="CoolBrand"/>
                    </Link>
                    <Link to="/" className='no-underline d-block d-sm-block d-md-block d-lg-none'>
                    <p className='home-phone'>TipTop</p>
                    </Link>
                    
                    <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <div class="navbar-nav ms-auto">
                            <Link to="/dienstleistungen" class="nav-item nav-link nav-font active"> <span className='bold'>Reinigungsdienste</span></Link>
                            <Link to="/karriere" class="nav-item nav-link nav-font active">Karriere</Link>
                            <Link to="/kontakt" class="nav-item nav-link nav-font active">Kontakt</Link>
                            <div className="nav-item nav-tel align-self-baseline">
                                <img src={tel} alt="Tel" width="30" height="30" className='call-icon'/> 07251 98 00 84
                            </div>
                        </div>
                    </div>
                </div>
            </nav>






    {/*<nav className="navbar navbar-expand-lg bg-white">
            <div className="container">
                <Link to="/" className="navbar-brand" >
                    <img src={logo} alt="Logo" width="120" height="80"/>
                </Link>
                <div className="d-flex">
                    <Link to="/contact" className="btn nav-btn nav-font">UNSER LEISTUNGEN</Link>
                    <Link to="/contact" className="btn nav-btn nav-font">KARRIERE</Link>
                    <Link to="/contact" className="btn nav-btn nav-font">KONTAKT</Link>
                    <div className="nav-tel">
                    <img src={tel} alt="Tel" width="30" height="30"/> 07251 98 00 84
                    </div>
                    
                </div>
            </div>
    </nav>*/}



        </div>

        


    );

}