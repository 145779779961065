import Cookies from "../Components/Cookies";
import Footer from "../Components/Footer";
import Nav from "../Components/Nav";
import Service from "../Components/Service";

export default function Services(){
    return(
        <div>
            <Cookies/>
            <Nav/>
            <Service/>
            <Footer/>
        </div>
    );
}