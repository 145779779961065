import Fassaden from '../Images/Fassadenreinigung.webp';
import Glasreinigung from '../Images/Glassreinigung.webp';
import Industriereinigung from '../Images/Industriereinigung.webp';
import Unterhaltsreinigung from '../Images/Unterhaltsreinigung.webp';
import Teppichreinigung from '../Images/Teppichreinigung.webp';
import Privatreinigung from '../Images/Privatreinigung.webp';
import Grundpflege from '../Images/Gruenpflege.webp';
import Winterdienst from '../Images/Winterdienst.webp';
import Solar from '../Images/Solaranlagereinigung.webp';
import CtaDienst from './CtaDienst';
import React, { useEffect } from 'react';



export default function Service(){
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return( 
        <div className="text-center">
            <h1 className="text-services-title">Unsere Dienstleistungen</h1>
            <p className="text-services-desc">Wir nehmen unsere Verantwortung ernst, den bestmöglichen Service zu bieten.
                <br/>Hier sehen Sie der Reinigungsdienstleistungen, die wir anbieten.</p>
                <div >
                
            <div className="d-flex flex-wrap justify-content-evenly ">
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home">
                            <img src={Fassaden} alt="Zwei Männer reinigen die Fassade eines Gebäudes." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Fassadenreinigung</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Reinigung von Fassaden</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Fensterollen, Jalousien und Fensterreinigung</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Reinigung mit Osmogerät</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>4.</span> Reinigung mit Antikalkmitteln</li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home-2">
                            <img src={Glasreinigung} alt="Mann putzt ein Fenster mit einem Wischmopp." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Glasreinigung</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Reinigung von Fenster und Fensterrahmen</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Reinigung Fensterollen und Jalousien</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Reinigung von Glastüren</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>4.</span> Reinigung von Wintergarten</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>5.</span> Reinigung von Dachfensterreinigung</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>6.</span> Andere Glaskonstruktionen auf Anfrage</li>

                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home-2">
                            <img src={Industriereinigung} alt="Mann trägt Schutzkleidung und wischt den Boden in einem Industriegebäude." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Industriereinigung</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Reinigung von Industriehallen</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Bodenreinigung und Wischpflege</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Reinigung von Industriemaschinen</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>4.</span> Weiteres auf Anfrage</li>
                            </ul>
                        </div>
                    </div>
            </div>
            <div class="d-flex flex-wrap justify-content-evenly">
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home-2">
                            <img src={Unterhaltsreinigung} alt="Frau lächelt und hält einen Mopp und Reinigungsmaterial." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Unterhaltsreinigung</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Rathaus und andere Öffentliche Einrichtungen</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Schule und Kindergärten</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Büroräume und Verwaltungsgebäude</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>4.</span> Viele Andere Bereiche</li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home-2">
                            <img src={Teppichreinigung} alt="Frau saugt einen Teppich." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Teppichreinigung</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Reinigung und Wäsche von Teppichen</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Teppiche gebürstet und gereinigt</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Staubsaugen von Teppichen</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>4.</span> Reinigung von tiefem Schmutz</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>5.</span> Mit einer professionellen <br/> Teppichreinigungsmaschine</li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home-2">
                            <img src={Privatreinigung} alt="Frau reinigt das Haus eines Kunden." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Privatreinigung</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Reinigung des gesamten Hauses</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Fensterreinigung und Bodenreinigung</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Reinigung von Produkten <br/> aus nichtrostendem Stahl</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>4.</span> Reinigung mit Antikalkmitteln</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>5.</span> Vermüllung</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-evenly">
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home-2">
                            <img src={Grundpflege} alt="Schneiden der Sträucher mit Spezialgeräten." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Grün und Graupflege</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Gartenarbeit: Rasenmähen, Heckenpflege</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Reinigung des Grases zwischen <br/> den Pflastersteinen</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Vollständige Reinigung des Gartens</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>4.</span> Hochdruckreinigung von Pflastersteinen</li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home-2">
                            <img src={Winterdienst} alt="Mann hält eine Schneeschaukel über die verschneite Straße." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Winterdienst</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Schneeräumung und Salzstreuung</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Einsatz von Schneeräumgeräten</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Schaufeln und Spezialmaschinen <br/> für die Schneeräumung</li>
                            </ul>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center text-center">
                        <div className="container margin-img-home-2">
                            <img src={Solar} alt="Ein Mann reinigt das Solarpanel mit einer speziellen Ausrüstung." className='img-dienst'/>
                            <p className="text-services-desc text-uppercase bold-dienst" style={{"color": "#af202f"}}>Solaranlagereinigung</p>
                            <ul>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>1.</span> Reinigung mit Osmogeräte</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>2.</span> Reinigung mit Antikalkmitteln</li>
                                <li className="text-services-desc left-dienst"><span className='span-hero-text bold'>3.</span> Reinigung nur zu bestimmten Jahreszeiten</li>
                            </ul>
                        </div>
                    </div>
            </div>
            <CtaDienst/>
        
        </div>
    );
}