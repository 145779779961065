import Nav from '../Components/Nav';
import Footer from  '../Components/Footer';
import { useState } from 'react';
import Cookies from '../Components/Cookies';
import {useCookies} from 'react-cookie';
import backgroundImgEmail from '../Images/contact/Background.png';
import gesendet from '../Images/contact/gesent.png';
import React, {useEffect} from 'react';

export default function Contact(){
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [tels, setTel] = useState("");
    const [msg, setMsg] = useState("");
    const [cookies, setCookie] = useCookies();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const handleSubmit = async (event) =>{
        event.preventDefault();
        if(firstName.length > 1 && lastName.length > 1){
            if(email.includes("@") && email.includes(".")){
                if(email.indexOf(".") < (email.length-1) &&
                     ((email.length-1) - email.indexOf(".")) >= 2 &&
                     ((email.length-1) - email.indexOf("@")) >= 4){
                        if(tels.length >= 5){
                            console.log(tels.length);
                            console.log(tels);
                            if(msg.length > 10){
                                await fetch('/api/', {
                                    method: 'POST',
                                    mode: 'cors',
                                    headers: {
                                        'content-type': 'application/json'
                                    },
                                    body: JSON.stringify({
                                        "api": 'tiptop',
                                        "firstName": firstName,
                                        "lastName": lastName,
                                        "email": email,
                                        "tel": tels,
                                        "msg": msg
                                    })
                                }).then((r) => {
                                    if(r.status === 200){
                                        setCookie("message-sent", 'true', {path: '/', maxAge: 604800});
                                        //window.location.href = '/kontakt';
                                    }
                                });
                            } else {
                                setMsg("");
                            }
                        } else{
                            setTel("");
                        }
                } else{
                    setEmail("");
                }
                
            } else{
                setEmail("")
            }
        } else{
            setFirstName("");
            setLastName("");
        }
    }
    if(cookies['message-sent'] !== 'true'){
        return(
            <div>
                <Cookies/>
                <Nav/>
                <div className="container-fluid" style={{"background-color": "#fff", "min-height": "85vh"}}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="container-contact-text">
                                <div className="text-start" style={{"margin-bottom": "7vh"}}>
                                        <p className="text-services-title">Kontaktieren Sie uns</p>
                                        <p className="text-services-desc">Wie können wir Ihnen helfen? Bei Fragen können Sie sich an uns wenden
                                            und wir werden Ihnen gerne weiterhelfen.</p>
                                </div>
                                <p className="text-contact-desc">Telefon: <span className="text-contact-span">07251 98 00 84</span></p>
                                <p className="text-contact-desc">Fax: <span className="text-contact-span">07251 30 31 99</span></p>
                                <p className="text-contact-desc margin-for-delimitation-contanct">E-Mail: <span className="text-contact-span">info@tip-top-gmbh.de</span></p>
                                <p className="text-contact-desc margin-for-delimitation-contanct">Standort <br/>
                                    <span className="text-contact-span">Werner-Von-Siemens-Str. 3, 76994 Forst</span></p>
                                <p className="text-contact-desc margin-for-delimitation-contanct">Sprechzeiten <br/>
                                    <span className="text-contact-span">Montag bis Freitag von 08:00 - 16:00 Uhr</span></p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="container-form">
                            <p className="text-contact-desc margin-for-delimitation-contanct">Kontaktformular </p>
                                <form class="row g-3 padding-and-font-forms" onSubmit={handleSubmit}>
                                    <span className="text-contact-desc">Ihre Name</span>
                                    <div class="col-md-6">
                                        
                                        <input type="text" class="form-control" id="vorname" placeholder="Vorname" onChange={(e) => setFirstName(e.target.value)}/>
                                        
                                    </div>
                                    <div class="col-md-6">
                                        
                                        <input type="text" class="form-control" id="inputEmail4" placeholder="Nachname" onChange={(e) => setLastName(e.target.value)}/>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputEmail4" class="form-label">Email</label>
                                        <input type="email" class="form-control" id="inputEmail4" placeholder="z. B. deinemail@gmail.com" onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputEmail4" class="form-label">Telefon</label>
                                        <input type="text" class="form-control" id="inputEmail4" placeholder="z. B. +49 171 123456" onChange={(e) => {setTel(e.target.value); console.log(tels);}}/>
                                    </div>
                                    <div class="col-md-12">
                                        <label for="inputEmail4" class="form-label">Ihre Nachricht</label>
                                        <textarea class="form-control textbox-contact" id="exampleFormControlTextarea1" rows="3" style={{"resize": "none"}} draggable="false"
                                            placeholder="Bitte Ihre Nachricht eingeben..." onChange={(e) => setMsg(e.target.value)}></textarea>
                                    </div>
                                    
                                    <div class="col-12 d-flex justify-content-center">
                                        <button type="submit" class="btn cta-btn text-white">Senden</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    } else {
        return(
            <div>
                <Cookies/>
                <Nav/>
                <div className="container-fluid" style={{"background-color": "#fff", "min-height": "85vh"}}>
                    <div className="row">
                        <div className="col-lg-6 col-sm-10 col-md-10">
                            <div className="container-contact-text">
                                <div className="text-start" style={{"margin-bottom": "7vh"}}>
                                        <p className="text-services-title">Kontaktieren Sie uns</p>
                                        <p className="text-services-desc">Wie können wir Ihnen helfen? Bei Fragen können Sie sich an uns wenden
                                            und wir werden Ihnen gerne weiterhelfen.</p>
                                </div>
                                <p className="text-contact-desc">Telefon: <span className="text-contact-span">07251 98 00 84</span></p>
                                <p className="text-contact-desc">Fax: <span className="text-contact-span">07251 30 31 99</span></p>
                                <p className="text-contact-desc margin-for-delimitation-contanct">E-Mail: <span className="text-contact-span">info@tip-top-gmbh.de</span></p>
                                <p className="text-contact-desc margin-for-delimitation-contanct">Standort <br/>
                                    <span className="text-contact-span">Werner-Von-Siemens-Str. 3, 76994 Forst</span></p>
                                <p className="text-contact-desc margin-for-delimitation-contanct">Sprechzeiten <br/>
                                    <span className="text-contact-span">Montag bis Freitag von 08:00 - 16:00 Uhr</span></p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-10 col-md-10">
                            <div className="d-flex justify-content-center text-center">
                                <span className="security-text-message text-muted">
                                    *Aus Sicherheitsgründen können Sie die nächste Nachricht erst morgen verschicken.
                                </span>
                            </div>
                            <div className="add-bg-message-sent" style={{backgroundImage: `url(${backgroundImgEmail})`}}>
                              <div className="d-flex justify-content-center text-center">
                                <img src={gesendet} alt="" width="60" height="60" className='sent-icon'/>
                                <span className="message-sent-title">Nachricht gesendet</span>
                              </div>
                              <div className="d-flex justify-content-center text-center">
                                <span className="message-sent-desc">Vielen Dank, dass Sie uns geschrieben haben, 
                                wir werden uns so schnell wie möglich bei Ihnen melden.</span>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        );
    }
    
}