import Footer from "../Components/Footer";
import Nav from "../Components/Nav";

export default function Credits(){
    return(
        <div>
            <Nav/>
            <div className="container container-credits">
                <br/>
                <p className="text-services-desc">Diese Seite dient dazu, den Eigentümern dieser Bilder und Icons die rechtmäßige Anerkennung zukommen zu lassen.</p>
                <h5>Bilder von Unsplash</h5>
                <div className="credits">
                    <p> Photo by <a href="https://www.freepik.com/free-photo/housewife-young-smiling-woman-with-basin-with-cleansing-appliances_22706553.htm#query=cleaning&position=34&from_view=keyword">Image by zinkevych</a> on Freepik</p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/young-woman-with-rubber-gloves-ready-clean_10320465.htm#page=2&query=cleaning&position=42&from_view=keyword">Image by Racool_studio</a> on Freepik</p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/two-workers-washing-windows-outside-building_29220103.htm#page=3&query=cleaning&position=17&from_view=search&track=sph">Image by frimufilms</a> on Freepik</p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/man-worker-firld-by-solar-panels_5176473.htm#page=3&query=cleaning&position=44&from_view=search&track=sph">Image by senivpetro</a> on Freepik</p>
                    <p> Photo by  <a href="https://www.freepik.com/free-photo/professional-cleaner-wearing-protection-uniform-cleaning-floor-production-plant_11450677.htm#page=4&query=cleaning&position=18&from_view=search&track=sph">Image by aleksandarlittlewolf</a> on Freepik</p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/smiling-young-woman-holding-rag-detergent-kitchen_13908635.htm#page=9&query=cleaning&position=26&from_view=search&track=sph">Image by serhii_bobyk</a> on Freepik</p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/hands-gardener-orange-gloves-are-trimming-overgrown-green-shrub-using-hedge-shears-sunny-backyard-worker-landscaping-garden-close-up_27213026.htm#query=garden%20maintenance&position=2&from_view=search&track=sph">Image by YuliiaKa</a> on Freepik</p>
                    <p> Photo by  <a href="https://www.freepik.com/free-photo/young-smiling-woman-vacuum-cleaning-carpet_1281465.htm#query=carpet%20cleaning&position=41&from_view=search&track=sph">Image by yanalya</a> on Freepik</p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/male-standing-snowy-field-holding-snow-shovel_10720745.htm#query=removing%20snow&position=2&from_view=search&track=sph#position=2&query=removing%20snow">Image by wirestock</a> on Freepik</p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/male-cleaning-window_7713453.htm#page=2&query=window%20cleaning&position=13&from_view=search&track=sph">Freepik</a></p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/people-taking-care-office-cleaning_25219974.htm#query=people%20cleaning&position=1&from_view=search&track=sph">Freepik</a></p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/man-washing-windows-using-spray-detergent_26146215.htm#query=people%20cleaning&position=13&from_view=search&track=sph">Image by senivpetro</a> on Freepik</p>
                    <p> Photo by <a href="https://www.freepik.com/free-photo/group-activists-friends-throw-lot-garbage-bag_7096961.htm#page=3&query=people%20cleaning&position=43&from_view=search&track=sph">Image by teksomolika</a> on Freepik</p>
                </div>
                <br/>
                <h5>Icons von Flaticon</h5>
                <div className="credits">
                    <p><a href="https://www.flaticon.com/free-icons/phone" title="phone icons">Phone icons created by Gregor Cresnar - Flaticon</a></p>
                    <p><a href="https://www.flaticon.com/free-icons/email" title="email icons">Email icons created by Freepik - Flaticon</a></p>
                    <p><a href="https://www.flaticon.com/free-icons/location-pin" title="location pin icons">Location pin icons created by Pixel perfect - Flaticon</a></p>
                    <p><a href="https://www.flaticon.com/free-icons/job-description" title="job description icons">Job description icons created by surang - Flaticon</a></p>
                </div>
                <br/>
            </div>
            <Footer/>
        </div>
    )
}