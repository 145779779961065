import Footer from "../Components/Footer";
import Nav from "../Components/Nav";

export default function Impressum(){
    return(
        <div>
            <Nav/>
            <div className="container container-credits">
                <h1>Impressum</h1>
                <div className="impressum">
                    <p>Angaben gemäß § 5 TMG</p>
                    <p>Tip Top Gebäudereinigung Dienstleistungen NG GmbH <br/> 
                    Werner-von-Siemens-Str. 3<br/> 
                    76694 Forst <br/></p>
                    <p><span className="bold">Vertreten durch: </span><br/>
                    Geschäftsführerin Irina Dobrinescu<br/> </p>
                    <p><span className="bold">Registereintrag: </span><br/> 
                    Eintragung im Handelsregister.<br/>
                    Registergericht: Mannheim<br/>
                    Registernummer: 718953<br/></p>
                    <p><span className="bold">Haftungsausschluss: </span><br/><br/>
                    <span className="bold">Haftung für Links</span><br/><br/>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p><br/> 
                    Impressum vom <a href="https://www.impressum-generator.de">Impressum Generator</a> der <a href="https://www.kanzlei-hasselbach.de/standorte/frankfurt/" rel="nofollow">Kanzlei Hasselbach, Frankfurt</a>
                </div>
            </div>
            <Footer/>
        </div>
    );
}