import './Dienst.css'
import {Link} from 'react-router-dom';
export default function DienstleistungenHomepage(){
    return (
        <div>
            <div className="d-flex flex-column flex-lg-row flex-md-row justify-content-center justify-content-md-center  justify-content-lg-evenly">
                <div className="container-dienst-text text-center">
                    <h3 className="text-dienst-title-industrie">Industriereinigung</h3>
                    <div className="text-dienst-description">
                        <p>Reinigung von Industriehallen</p>
                        <p>Bodenreinigung und Wischpflege</p>
                        <p>Reinigung von Industriemaschinen</p>
                        <p>Weiteres auf Anfrage</p>
                    </div>
                </div>
                <div className="container-dienst-home d-flex justify-content-center">
                    <svg className="img-svg-dienst-industrie">
                        <title>Mann trägt Schutzkleidung und wischt den Boden in einem Industriegebäude.</title>
                    </svg>
                </div>                
        </div>
        <div className="d-flex flex-column-reverse flex-lg-row flex-md-row justify-content-center justify-content-md-center justify-content-sm-center  justify-content-lg-evenly">
                <div className="container-dienst-home-privat d-flex justify-content-center">
                    <svg className="img-svg-dienst-privat">
                        <title>Frau reinigt das Haus eines Kunden.</title>
                    </svg>
                </div>
                <div className="container-dienst-text text-center">
                    <h3 className="text-dienst-title-privat">Privatreinigung</h3>
                    <div className="text-dienst-description">
                        <p>Reinigung von Mobiliar</p>
                        <p>Fensterreinigung</p>
                        <p>Bodenreinigung</p>
                        <p>Weiteres auf Anfrage</p>
                    </div>
                </div>
                
        </div>
        <div className="container-dienst-info-btn d-flex justify-content-center">
            <Link to="/dienstleistungen" className="btn btn-outline">Mehr Dienstleistungen</Link>
         </div>

        </div>
        
    );
}