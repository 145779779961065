import {Link} from 'react-router-dom';
export default function Footer(){
    return(
        <div className="container-fluid footer-color">
        <footer className="d-flex flex-wrap justify-content-between align-items-center py-3">
        <ul className='nav col-sm-6 col-md-6 col-lg-6'>
          <li  className="nav-link px-2 copy-font">© 2023 TipTop GmbH. All Rights Reserved</li>
        </ul>
        <ul className="nav col-sm-6 col-md-6 col-lg-6 d-flex justify-content-end flex-wrap">
          <li className="nav-item"><Link to="/datenschutz" className="nav-link px-2 copy-font">Datenschutz</Link></li>
          <li className="nav-item"><Link to="/impressum" className="nav-link px-2 copy-font">Impressum</Link></li>
          <li className="nav-item"><Link to="/credits" className="nav-link px-2 copy-font">Credits</Link></li>
          <li className="nav-item"><Link to="/cookie-richtlinie" className="nav-link px-2 copy-font">Cookie-richtlinie</Link></li>
        </ul>
        </footer>
  </div>
    );
}