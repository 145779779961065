import './Cookies.css';
import {useCookies} from 'react-cookie';
import {Link} from 'react-router-dom';

export default function Cookies(){
    const [cookies, setCookie] = useCookies();
    /*const location = useLocation();
    useEffect(() => {
        if(cookies['agreed'] !== 'true' && cookies['agreed-all'] !== 'true' && location.pathname !== '/'){
            return window.location.href='/';
        }
        
    });*/
    const acceptAllCookies = () =>{
        setCookie("agreed-all", 'true', {path: '/', maxAge: 604800});
        return window.location.href='/'
    }
    const acceptDefaultCookies = () =>{
        setCookie("agreed", 'true', {path: '/', maxAge: 604800});
        return window.location.href='/'
    }
    if(cookies['agreed'] !== 'true' && cookies['agreed-all'] !== 'true'){
        return (
            <div className="container-cookies-consent shadow-lg">
                <p className="cookies-consent-title">Cookie-Einstellungen</p>
                <p className="cookies-consent-desc">Wir nutzen Cookies, um Inhalte zu personalisieren und die Zugriffe auf unsere Website zu analysieren. Sie können wählen, ob Sie nur für die Funktion der Website notwendige Cookies akzeptieren oder auch Tracking-Cookies zulassen möchten. Weitere Informationen finden Sie in unserer <Link to="/datenschutz">Datenschutzerklärung</Link> und <Link to="/cookie-richtlinie">Cookie-Richtlinie</Link>.</p>
                <hr className="hr-cookies-consent"/>
                <div className="row">
                    <div className="col-4 d-flex justify-content-center">
                        <button className="btn btn-lg btn-danger cookies-consent-btn-margs" onClick={acceptDefaultCookies}>Cookies ablehnen</button>
                    </div>
                    <div className="col-8 d-flex justify-content-center">
                        <button className="btn btn-lg btn-success cookies-consent-btn-margs" onClick={acceptAllCookies}>Akzeptiere alle Cookies</button>
                    </div>
                </div>
            </div>
        );
    }
}