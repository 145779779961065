import {Link} from 'react-router-dom';

export default function CtaDienst(){
    return(
        <div className='container-fluid-cta'>
            <p className='text-services-title'>Angebot anfordern!</p>
            <p className='text-services-desc2'>Wir machen Ihnen ein individuelles Angebot, das auf Ihre Bedürfnisse zugeschnitten ist,</p>
             <p className="text-services-desc2">damit Sie den besten Reinigungsservice erhalten.</p>
            <div className="container-dienst-info-btn d-flex justify-content-center">
                <Link to="/kontakt" className="btn btn-primary cta-btn shadow-lg">Jetzt informieren</Link>
            </div>
        </div>  
    );
}