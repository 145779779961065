import Footer from "../Components/Footer";
import Nav from "../Components/Nav";


export default function Notfound(){
    return(
        <div>
            <Nav/>
            <div className="container-404 text-center">
                <p className="text-services-title">404 NOT FOUND</p>
            </div>
            <Footer/>
        </div>
    );
}