import './Fragen.css';

export default function Fragen(){
    return(
        <div className="faq-container-margin">
            <div className="container-services text-start">
                <h2 className="text-services-title">Häufig gestellte Fragen</h2>
                <p className="text-services-desc">Werfen Sie einen Blick auf die Antworten auf diese häufig gestellten Fragen, um unsere Arbeitsweise besser zu verstehen. </p>
            </div>
            <div class="accordion accordion-flush faq-container" id="accordionPanelsStayOpenExample">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                        <span className='faq-text'>Warum mit uns arbeiten?</span>
                    </button>
                    </h2>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        <div class="accordion-body body-faq">
                            <ul>
                            <li><span className='span-hero-text bold'>1.</span> Wir sind bestrebt, die beste Arbeit zu leisten.</li>
                            <li><span className='span-hero-text bold'>2.</span> Wir sind sehr freundlich und hören auf Ihre Bedürfnisse.</li>
                            <li><span className='span-hero-text bold'>3.</span> Weil ein gut gereinigtes Haus oder Geschäft immer als ein gutes Zeichen gesehen wird.</li>
                            </ul>
                            <p>Unser Reinigungsunternehmen besteht aus einem gut ausgebildeten Team von Reinigungsfachleuten, die für jede Aufgabe die effektivste Ausrüstung verwenden.</p>
                            <p>Von Privathaushaltreinigung bis reinigung für Unternehmen können Sie sich darauf verlassen, dass wir die beste Arbeit leisten.</p>
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    <span className='faq-text'>Wie hoch ist der Preis für Ihre Dienstleistungen?</span>
                    </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body body-faq">
                       <p>Der Preis für jede Dienstleistung hängt von einer Reihe verschiedener Faktoren ab, wie z. B. der Anzahl der auszuführenden Aufgaben und anderen.</p>                    
                        <p>Zögern Sie nicht, uns zu diesem Thema zu kontaktieren und wir können sicher darüber sprechen.</p>
                    
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    <span className='faq-text'>Bieten Sie auch Dienstleistungen an meinem Standort an?</span>
                    </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body body-faq">
                    <p>Wir bieten Ihnen Reinigungsdienstleistungen rund um Bruchsal und in der näheren Umgebung wie Forst, Karlsruhe, Bad Schönborg oder Bretten und vielen weiteren Orten. </p>
                        <p>Wenn Sie unseren Service in einem anderen Ort in der Nähe benötigen, zögern Sie nicht uns zu kontaktieren und wir können alle Details besprechen.</p>
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFour">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    <span className='faq-text'>Wie kann ich einen Termin vereinbaren?</span>
                    </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body body-faq">
                       <p>Sie können einen Termin entweder telefonisch oder per E-Mail vereinbaren. Sie können auch unser Kontaktformular verwenden, das Sie auf der Kontaktseite finden.</p>
                    </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFive">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    <span className='faq-text'>Welche Art von Reinigungsdienstleistungen bieten Sie an?</span>
                    </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                    <div class="accordion-body body-faq">
                        <p>Unsere Dienstleistungen umfassen Fassadenreinigung, Glasreinigung, Industriereinigung, Unterhaltsreinigung, Teppichreinigung, Privatreinigung und Reinigung von Solaranlagen.</p>
                        <p>Wir bieten auch Winterdienst, Grün und Grundpflege.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
}