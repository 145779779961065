import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import Hero from '../Images/Reinigungskraft.png';
import {Link} from "react-router-dom";
import Carousel1 from '../Images/slider/IMG_3482.jpg';
import Carousel2 from '../Images/slider/IMG_3498.jpg';
import Carousel3 from '../Images/slider/IMG_3504.jpg';
import Email from '../Images/E-mail-Symbol.webp';
import Location from '../Images/Standort-Symbol.webp';
import Phone from '../Images/Telefon-Symbol.webp';
import Cookies from'../Components/Cookies';
import DienstleistungenHomepage from '../Components/DienstleistungenHomepage.jsx';
import logo from '../Images/Logo-Tip-Top.webp';
import Fragen from '../Components/Fragen';

export default function Home(){
    return(
        <div>
            <Cookies/>
            <Nav/>
            
            <div className="container-fluid row">
                <div className="col-lg-6 col-sm-12 col-md-10">
                    <div className="container-for-hero-text">
                        <div className=' d-block d-md-block d-lg-none d-flex'>
                            <img src={logo} height="100" Width="108" alt="TipTop Gebäudereinigung Dienstleistungen Logo"/>
                        </div>
                        <h1 class="text-start font-mare-hero">
                            <span className="highlighted-text">TipTop GmbH</span><br/>
                            <span className='span-hero-text'>Gebäudereinigung</span> in Bruchsal und in der nähe
                        </h1>
                        <p class="text-start text-hero-mic">Von der Reinigung für Unternehmen bis hin zur Reinigung für Privathaushalte - wir sorgen dafür, dass Sie den besten Reinigungsservice erhalten, den es gibt.</p>
                    </div>
                     <div className="container-cta-btn">
                        <Link to="/kontakt" className="btn cta-btn pulse">Jetzt kontaktieren</Link>
                     </div>
                </div>
                <div className="col-lg-6 col-sm-12 col-md-10 d-none d-sm-block d-sm-none d-md-block d-md-none d-lg-block">   
                 <img src={Hero} className="img-hero d-block mx-auto" alt="Putzfrau mit einem Korb voller Reinigungsmittel"/>
                </div>
            </div>
             
            <div className="container-services text-center">
                    <h2 className="text-services-title">Ein Blick auf unsere Reinigungsdienste</h2>
                    <p className="text-services-desc">Wir haben ein großes Angebot an Reinigungsdienstleistungen, von denen Sie profitieren können.</p>
                    <p className="text-services-desc">Klicken Sie auf die Schaltfläche unten, um sie alle zu sehen.</p>
                    
            </div>
      
                <DienstleistungenHomepage/>
                <Fragen/>

            <div className="our-team-container">
                <div className='svg-right'></div>
                <div className="container-carousel-text text-start">
                    <div className="row">
                        <div className="col-2">
                         <p></p>
                        </div>
                        <div className="col-8">
                            <h2 className="text-services-title">Uns besser kennenlernen</h2>
                            <p className="text-services-desc">Unsere Reinigungsfirma besteht aus professionellen Reinigungskräften, die alle Reinigungsaufgaben sorgfältig erledigen.
                            Zu <span className='span-hero-text bold'>unschlagbaren Preisen</span> stellen wir sicher, dass Sie auch die besten Dienstleistungen erhalten.</p>
                         </div>
                         <div className="col-2 ">
                            <div className='svg-left'></div>
                         </div>
                    </div>
                </div>

                <div className="container-fluid d-flex justify-content-center">
                    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="false">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img src={Carousel1} className="d-block img-carousel" alt="Mann reinigt den Boden eines Gebäudes."/>
                            <div className="carousel-caption d-md-block d-sm-block d-xs-block">
                                <h3>Wir sind ein kollaboratives Team</h3>
                                <p className='carousel-desc'>Alles, was man besser machen kann, wird im Team gemacht, deshalb wissen wir um die Bedeutung der Zusammenarbeit.</p>
                            </div>
                            </div>
                            <div className="carousel-item">
                            <img src={Carousel2} className="d-block img-carousel" alt="Alter Mann reinigt den Boden eines Industriegebäudes."/>
                            <div className="carousel-caption d-md-block d-sm-block d-xs-block">
                                <h3>Wir haben Liebe zum Detail</h3>
                                <p className='carousel-desc'>Unsere Spezialität ist es, dafür zu sorgen, dass alles besonders sauber ist.</p>
                            </div>
                            </div>
                            <div className="carousel-item">
                            <img src={Carousel3} className="d-block img-carousel" alt="Drei Männer reinigen die Fassade eines Gebäudes."/>
                            <div className="carousel-caption d-md-block d-sm-block d-xs-block">
                                <h3>Wir sind sympathisch</h3>
                                <p className='carousel-desc'>Die beste Gruppe von Reinigungskräften ist gesellig und nett, denn wir machen alles mit Freude.</p>
                            </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>     
                </div>
            </div>


            <div className="container-services text-start">
                    <h2 className="text-services-title">Kontaktieren Sie uns</h2>
                    <p className="text-services-desc">Wir bieten Reinigungsdienstleistungen nicht nur in Bruchsal, sondern auch in Forst, </p>
                    <p className="text-services-desc">Karlsruhe, Bad Schönborn, Bretten und vielen anderen Orten an.</p>
                    <p className="text-services-desc">Kontaktieren Sie uns und wir machen Ihnen ein Angebot.</p>
                    
            </div>
            <div className="d-flex flex-wrap justify-content-evenly d-padding">
                    <div className="d-flex justify-content-start text-center">
                        <div className="container margin-img-home">
                            <img src={Email} alt="Weißes E-Mail-Symbol auf rotem Hintergrund." className='icons'/>
                            <p className="text-services-desc1">info@tip-top-gmbh.de</p>
                        </div>
                      
                    </div>
                    <div className="d-flex justify-content-start text-center">
                        <div className="container margin-img-home">
                            <img src={Phone} alt="Weißes Telefon-Symbol auf rotem Hintergrund." className='icons'/>
                            <p className="text-services-desc1">07251 98 00 84 <br/> <span className="text-muted">Montag bis Freitag von<br/>08:00 - 16:00 Uhr</span></p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-start text-center">
                        <div className="container margin-img-home">
                            <img src={Location} alt="Weißes Standort-Symbol auf rotem Hintergrund." className='icons'/>
                            <p className="text-services-desc1">Werner-von-Siemens-Str. 3 <br/>76994 Forst</p>
                        </div>
                    </div>
                    
                        
            </div>
            <Footer/>
        </div>
    );
}