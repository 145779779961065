import Nav from '../Components/Nav';
import Footer from '../Components/Footer';
import JobImg from '../Images/Job-Symbol.webp';
import Cookies from '../Components/Cookies';
export default function Career(){
    return(
        <div>
            <Cookies/>
            <Nav/>
                <div className="container-fluid" style={{"background-color": "#fff", "min-height": "85vh"}}>
                    <div className="container-services">
                        <p className="text-career-about-us">Über uns und unsere Jobs</p>
                        <p className="text-career-about-us-desc">Wir haben ein großartiges Team von Mitarbeitern, die sich dafür einsetzen,
                        das beste aus jedem Auftrag herauszuholen, den sie erledigen. <br/> Möchten Sie sich uns anschließen?</p>
                        <p className="text-career-about-us-desc extra-career-text-margin"> Bewerben Sie sich auf eine der folgende Stellen, die am besten zu Ihnen passt.</p>
                        <div className="d-flex justify-content-center">
                            <div className="d-flex flex-column text-center">
                                <div className="d-flex justify-content-center">
                                    <img src={JobImg} width="350" height="350" alt="" className='dienst-img'/>
                                </div>
                                <p className="text-job-1">Zurzeit haben wir leider Keine Stellen zu vergeben.</p>
                                <p className="text-job-2">Schauen Sie bald wieder auf dieser Seite vorbei, da wir neue Stellen eröffnen werden.</p>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer/>
        </div>
    );
}