
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
//import $ from 'jquery';
//import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import Contact from './Pages/Contact';
import Career from './Pages/Career';
import Datenschutz from './Pages/Datenschutz';
import CookiesRichtlinie from './Pages/Cookies-Richtlinie';
import Impressum from './Pages/Impressum';
import Credits from './Pages/Credits';
import Services from './Pages/Services';
import Notfound from './Pages/Notfound';


export default function App() {
  
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route path="/karriere" element={<Career />} />
      <Route path="/datenschutz" element={<Datenschutz />} />
      <Route path="/cookie-richtlinie" element={<CookiesRichtlinie/>} />
      <Route path="/impressum" element={<Impressum/>} />
      <Route path="/credits" element={<Credits/>} />
      <Route path="/dienstleistungen" element={<Services/>} />
      <Route path="*" element={<Notfound/>}/>
    </Routes>
  )
}
